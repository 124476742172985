<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">BLOCK SESSION</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>

    <el-divider class="mv-10 o-020"></el-divider>

    <div class="flex gaps">
      <div class="box flex column gaps" style="width: 450px;">
        <div class="pt-16 hint-text">DATE</div>
        <div class="flex gaps">
          <div class="box">
            <el-date-picker
              size="small"
              v-model="day"
              type="date"
              :picker-options="pickerOptions"></el-date-picker>
          </div>
          <div class="box">
            <el-button size="small" @click="onClickHistory">History</el-button>
          </div>
        </div>

        <div class="ph-5 mr-15 flex">
          <div class="box grow">
            <div class="flex column mr-20" v-for="(s, index) in sessions" :key="index + '-session'">
              <div class="flex" :class="{'bg-primary-light' : selected === index}">
                <div class="box align-vertical ph-5">
                  <el-switch :value="isBlock(s)" @click.native="onClickSwitch(index)"></el-switch>
                </div>
                <div class="info box grow flex clickable" @click="onClickSession(index)">
                  <div class="name box grow flex column justify-center p-10">
                    <div class="fullname fs-18">
                      <strong>{{s.time}}</strong>
                    </div>
                  </div>
                  <div class="box grow flex column justify-space-around">
                    <div class="box fs-14 secondary-text">
                      <span class="pr-10" :class="{'accent-text' : booksBySessionIdx(index).length > 0}">{{s.left}} / {{s.capacity}}</span>
                      <span class="accent-text" v-if="booksBySessionIdx(index).length > 0">
                        ({{booksBySessionIdx(index).length}} books)
                      </span>
                      <span v-else>no books</span>
                    </div>
                  </div>
                </div>
              </div>
              <el-divider class="mv-4 o-020"></el-divider>
            </div>
          </div>
        </div>
      </div>
      <vue-scroll
            class="box grow scroll-area flex"
            :settings="settings"
            :style="{height: scrollHeight + 'px'}">
        <div class="box grow flex column gaps">
          <div class="box">
            <el-card class="ph-5 mr-15" v-if="selected > -1">
                <div class="p-16">
                  <h3 class="mv-0">{{selectedDate}}, {{sessions[selected].time}}</h3>
                  <h4 class="mv-0 hint-text">{{sessions[selected].name}}</h4>
                  <h4 v-if="isBlock(sessions[selected])"
                    class="mv-0 accent-text">blocked</h4>
                  <h4 v-else class="mv-0 hint-text">active</h4>
                </div>
                <div class="flex column mr-20"
                  v-for="(b, index) in booksBySessionIdx(selected)" :key="index + '-book'">
                  <div class="flex">
                    <div class="info box grow flex">
                      <div class="name box grow flex column justify-center p-10">
                        <div class="fullname fs-18">
                          <strong>{{b.customer.first}} {{b.customer.surname}}</strong>
                        </div>
                      </div>
                      <div class="box grow flex column justify-space-around">
                        <div class="box fs-14 hint-text">
                          {{b.customer.email}}
                        </div>
                        <div class="box fs-14 hint-text">
                          {{b.customer.phone}}
                        </div>
                        <div class="box fs-14">
                          {{b.ticket.persons}} {{b.ticket.name}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <el-divider class="mv-10 o-020"></el-divider>
                </div>
            </el-card>

            <el-card class="ph-5 mr-20" v-else>
              <div class="p-16">
                <h3 class="mv-0">History of block sessions</h3>
              </div>
              <div class="flex column mr-20"
                v-for="(b, index) in blocks" :key="index + '-block'">
                <div class="box grow flex">
                  <div class="box align-vertical ph-5">
                    <el-switch :value="b.isBlock"></el-switch>
                  </div>
                  <h4 class="box info-text justify-center p-10 mv-0">{{b.day}}, {{b.sessionTime}}</h4>
                </div>
                <div class="box grow pl-60 pb-10"
                  v-for="(r, idx) in b.reason.reverse()" :key="idx + '-' + index + '-reason'">
                  <div class="hint-text flex justify-space-between">
                    <span>{{dateFormat(r.date)}}</span>
                    <span>{{r.author}}</span>
                    <span>{{r.posName}}</span>
                  </div>
                  <div class="">
                    <span class="accent-text pr-28" v-if="r.isBlock">block</span>
                    <span class="warning-text pr-10" v-else>unblock</span>
                    <span>{{r.reason}}</span>
                  </div>

                  <el-divider class="mv-2 o-010"></el-divider>
                </div>

                <el-divider class="mv-10 o-020"></el-divider>
              </div>
            </el-card>
          </div>
        </div>
      </vue-scroll>
    </div>

    <el-dialog title="Block Session" :visible.sync="dialogConfirmVisible">
      <div class="pb-16 fs-18">
        <span class="pr-10">Are you sure you want to
          <strong v-if="isBlock(blockedSession)"
            class="accent-text"> unblock </strong>
          <strong v-else
            class="accent-text"> block </strong>
          the session?</span>
        <br><strong class="accent-text">{{selectedDate}}, {{blockedSession.time}}</strong>
      </div>
      <div class="pb-16">Be sure to include the reason.</div>
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="reason">
          <el-input type="textarea" v-model="form.reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogConfirmVisible = false">Cancel</el-button>
        <el-button type="primary" @click="confirm('form')">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'BlockSession',

  data () {
    return {
      selected: -1,
      day: new Date(),
      blockedSession: { time: '', name: '' },
      blockedSessionIndex: -1,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() + (1000 * 60 * 60 * 24) < Date.now() ||
           time.getTime() - (1000 * 60 * 60 * 24 * 30) > Date.now()
        }
      },
      settings: {
        maxScrollbarLength: 60
      },
      dialogConfirmVisible: false,
      form: {
        reason: ''
      },
      rules: {
        reason: [
          { required: true, message: 'reason is required', trigger: 'blur' }
        ]
      }
    }
  },

  computed: {
    ...mapState({
      innerHeight: state => state.nav.innerHeight,
      blocks: state => state.calendar.blocks
    }),
    ...mapGetters(['sessionsByDay', 'getBooksBySessionIdx']),
    selectedDate () {
      return new Date(new Date(this.day).toString().split('GMT')[0] + ' UTC')
        .toISOString().slice(0, 10)
    },
    sessions () {
      return this.sessionsByDay[this.selectedDate]
    },
    scrollHeight () {
      return this.innerHeight - 137
    }
  },
  methods: {
    ...mapActions(['addBlock', 'getCalendar']),
    isBlock (item) {
      return item.hasOwnProperty('block') &&
        item.block.hasOwnProperty('isBlock') &&
        item.block.isBlock
    },
    onClickSwitch (index) {
      this.blockedSession = this.sessions[index] || { time: '', name: '' }
      this.blockedSessionIndex = index
      this.dialogConfirmVisible = true
    },
    onClickSession (index) {
      this.selected = index
    },
    booksBySessionIdx (index) {
      return this.getBooksBySessionIdx[this.selectedDate + '-' + index] || []
    },
    onClickHistory () {
      this.selected = -1
    },
    dateFormat (date) {
      let today = new Date(date)
      let options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }
      return today.toLocaleString('en-GB', options)
    },
    confirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addBlock({
            day: this.selectedDate,
            sessionIdx: this.blockedSessionIndex,
            sessionTime: this.blockedSession.time,
            sessionName: this.blockedSession.name,
            reason: this.form.reason,
            posName: localStorage.getItem('posname') || '',
            isBlock: !this.isBlock(this.blockedSession)
          })
            .then(() => {
              this.getCalendar()
              this.dialogConfirmVisible = false
            })
            .then(() => {
              let mes = this.isBlock(this.blockedSession) ? 'unblock' : 'block'
              this.$message({
                message: 'session ' + mes + ' successfully',
                type: 'success'
              })
            })
        }
      })
    }
  }
}
</script>
